import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useRouter } from 'next/router';
import type { FC } from 'react';

import { TRACKING_DATA_QUERY_KEY, useAnalytics } from '@tracking/data';
import { useFormatMessage } from '@tracking/i18n';
import { AuthForm, Modal } from '@tracking/ui';

import { useAuthState } from '$src/modules/auth';
import { useNotification } from '$src/modules/notification';
import type { Error } from '$src/types';

type FormValues = {
  email: string;
};

type Props = {
  onModalClose: () => void;
};

export const AuthModal: FC<Props> = ({ onModalClose }) => {
  const formatMessage = useFormatMessage();
  const router = useRouter();
  const authContext = useAuthState();
  const queryClient = useQueryClient();
  const notificationContext = useNotification();
  const analytics = useAnalytics();

  const { tosId } = router.query;

  const { mutate, isLoading, error } = useMutation(
    ({ email }: FormValues) =>
      axios.post('/api/auth', { email, tos_id: tosId, locale: router.locale }),
    {
      onSuccess: ({ data }) => {
        queryClient.setQueryData([TRACKING_DATA_QUERY_KEY], data);
        authContext?.setAuthenticationValue(true);
        notificationContext?.showNotification(formatMessage('NOTIFICATION.EMAIL_VERIFIED'));
        onModalClose();
      },
    }
  );

  const errorText = (error as Error | undefined)?.response.data.error;

  const onSubmit = (formValues: FormValues) => {
    analytics.capture('verification_confirmation');
    mutate(formValues);
  };

  return (
    <Modal onClose={onModalClose} headerText={formatMessage('AUTH.VERIFICATION')}>
      <AuthForm
        emailInputDataTest="page-auth-email-input"
        authVerifyButtonDataTest="page-auth-verify-button"
        authCancelButtonDataTest="page-auth-cancel-button"
        onPosthogCapture={() => analytics.capture('input_email_address')}
        isLoading={isLoading}
        onSubmit={onSubmit}
        onCancel={onModalClose}
        errorText={errorText}
      />
    </Modal>
  );
};
