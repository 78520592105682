import { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react';

import { Text } from '$src/components';

import * as styles from './notification.css';

type NotificationContextType = {
  showNotification: (text: string) => void;
};

const notificationContext = createContext<NotificationContextType | null>(null);

export const NotificationProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [notificationText, setNotificationText] = useState('');
  const [isNotificationVisible, setNotificationVisibility] = useState(false);

  const { Provider } = notificationContext;

  useEffect(() => {
    const timer = setTimeout(() => setNotificationVisibility(false), 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [isNotificationVisible]);

  const showNotification = (text: string) => {
    setNotificationText(text);
    setNotificationVisibility(true);
  };

  return (
    <Provider value={{ showNotification }}>
      {isNotificationVisible && (
        <div className={styles.notification}>
          <Text data-test="notification" textStyle="light1420" textColor="white">
            {notificationText}
          </Text>
        </div>
      )}
      {children}
    </Provider>
  );
};

export const useNotification = () => useContext(notificationContext);
