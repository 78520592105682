import { FC, Fragment, useState } from 'react';

import { getShowDetails, useAnalytics, useTrackingData } from '@tracking/data';
import { useFormatMessage } from '@tracking/i18n';
import { Button, Text } from '@tracking/ui';

import { useAuthState } from '$src/modules/auth';

import { AuthModal } from './auth-modal';
import * as styles from './authentication.css';

export const Authentication: FC = () => {
  const authContext = useAuthState();
  const analytics = useAnalytics();
  const formatMessage = useFormatMessage();
  const [isAuthModalVisible, setAuthModalVisibility] = useState(false);
  const { showDetailsButton } = useTrackingData(data => ({
    showDetailsButton: getShowDetails(data),
  }));

  const onModalClose = () => {
    analytics.capture('close_verification_modal');
    setAuthModalVisibility(false);
  };

  const onModalOpen = () => {
    analytics.capture('open_verification_modal_via_show_details_button');
    setAuthModalVisibility(true);
  };

  if (authContext?.isAuthenticated || !showDetailsButton) {
    return null;
  }

  return (
    <Fragment>
      <div className={styles.rootElement}>
        <Button
          onClick={onModalOpen}
          className={styles.showDetails}
          dataTest="page-auth-show-details-button"
        >
          <Text textColor="white">{formatMessage('AUTH.SHOW_DETAILS')}</Text>
        </Button>
        <Text textColor="darkGrey" textStyle="light1216">
          {formatMessage('AUTH.DESCRIPTION')}
        </Text>
      </div>
      {isAuthModalVisible && <AuthModal onModalClose={onModalClose} />}
    </Fragment>
  );
};
