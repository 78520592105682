import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { FC, ReactNode, useEffect, useState } from 'react';

import {
  ExpressionContextProvider,
  GetTrackingDataResponse,
  TRACKING_DATA_QUERY_KEY,
  TrackingDataProvider,
  trackingDataToExpressionContext,
} from '@tracking/data';

import { PageTrackingError, logError } from '$src/commons/error-handling';
import { useAuthState } from '$src/modules/auth';

import { getTrackingDataByToken, getTrackingDataByTosId } from './api';

const PAGE_NOT_FOUND = '/404';

type RouterPossibleQuery = {
  token?: string;
  tosId?: string;
};

export const DataProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [data, setData] = useState<GetTrackingDataResponse | null>(null);
  const router = useRouter();
  const authContext = useAuthState();
  const queryClient = useQueryClient();
  const { token, tosId } = router.query as RouterPossibleQuery;

  const isAuthenticated = authContext?.isAuthenticated;

  //TODO: replace with useQuery & get rid of the redundant `data` state
  useEffect(() => {
    const prefetchedData = queryClient.getQueryData<GetTrackingDataResponse>([
      TRACKING_DATA_QUERY_KEY,
    ]);

    if (prefetchedData) {
      setData(prefetchedData);
      return;
    }

    queryClient
      .fetchQuery([TRACKING_DATA_QUERY_KEY], () => {
        if (tosId) return getTrackingDataByTosId(tosId);
        if (token) return getTrackingDataByToken(token);
      })
      .then(dataResponse => dataResponse && setData(dataResponse))
      .catch(error => {
        logError(error as PageTrackingError);
        router.push(PAGE_NOT_FOUND);
      });
  }, [router, queryClient, token, tosId, isAuthenticated]);

  if (!data) {
    return null;
  }

  return (
    <TrackingDataProvider value={data}>
      <ExpressionContextProvider
        value={trackingDataToExpressionContext({ data, locale: router.locale ?? 'en-US' })}
      >
        {children}
      </ExpressionContextProvider>
    </TrackingDataProvider>
  );
};
